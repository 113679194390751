import { SidebarRouteExtended } from '@shared/models';

export const TimeTrackingSidebarItems: SidebarRouteExtended[] = [
  { identifier: 'dashboard',          path: 'list',                  icon: 'icon-tempton-portals',   title: 'Zeiterfassung',                                  dashboardTitle: 'Zeiterfassung Übersicht', sortByDefault: ['portal_review_first', 'created_at'], dashboardCardsEnabled: true, children: [

  { identifier: 'ar-approved',        path: 'list',                  icon: 'icon-circle-tick',       title: 'TN Kundenfreigabe vorhanden',  isInternal: true, dashboardTitle: 'Tätigkeitsnachweise Kundenfreigabe vorhanden', counter: 'approved',    color: 'green',  filter: 'approved' },
  { identifier: 'ar-rejected',        path: 'list',                  icon: 'icon-circle-cross',      title: 'TN Klärungsbedarf',            isInternal: true, dashboardTitle: 'Tätigkeitsnachweise Klärungsbedarf',           counter: 'rejected',    color: 'red',    filter: 'rejected' },
  { identifier: 'ar-open',            path: 'list',                  icon: 'icon-circle-time',       title: 'Warten auf TN Kundenfreigabe', isInternal: true, dashboardTitle: 'Warten auf TN Kundenfreigabe',                 counter: 'open',        color: 'orange', filter: 'open'     },

  { identifier: 'ar-open',            path: 'list',                  icon: 'icon-circle-time',       title: 'Offene TN',                    isCustomer: true, dashboardTitle: 'Offene Tätigkeitsnachweise',                   counter: 'open',        color: 'green',  filter: 'open'     },
  { identifier: 'ar-approved',        path: 'list',                  icon: 'icon-circle-tick',       title: 'Freigegebene TN',              isCustomer: true, dashboardTitle: 'Freigegebene Tätigkeitsnachweise',             counter: 'approved',    color: 'orange', filter: 'approved' },
  { identifier: 'ar-rejected',        path: 'list',                  icon: 'icon-circle-cross',      title: 'Abgelehnte TN',                isCustomer: true, dashboardTitle: 'Abgelehnte Tätigkeitsnachweise',               counter: 'rejected',    color: 'red',    filter: 'rejected' },

  { identifier: 'ar-failed-erp',      path: 'failed-erp?tab=ar',     icon: 'icon-warning',           title: 'Fehlerhafte Übertragungen',    isInternal: true, dashboardTitle: 'Fehlerhafte Übertragungen',                    counter: 'ar-failed',   color: 'orange', extra: true        },
  { identifier: 'ar-new',             path: 'new-activity-report',   icon: 'icon-time-tracking-add', title: 'TN erstellen',                 isInternal: true, dashboardTitle: 'TN erstellen' },
]},

  { identifier: 'vr-dashboard',       path: 'vr-list',               icon: 'icon-holiday',           title: 'Urlaubserfassung',                               dashboardTitle: 'Urlaubserfassung', sortByDefault: ['customer_state_priority', 'created_at'], betaFlag: 'vacation_requests', children: [

  { identifier: 'vr-approved',        path: 'vr-list',               icon: 'icon-circle-tick',       title: 'Freigegebener Urlaub',         isInternal: true, dashboardTitle: 'Freigegebener Urlaub',                         counter: 'vr-approved', color: 'green',  filter: 'approved' },
  { identifier: 'vr-open',            path: 'vr-list',               icon: 'icon-circle-time',       title: 'Klärungsbedarf Urlaub ',       isInternal: true, dashboardTitle: 'Klärungsbedarf Urlaub ',                       counter: 'vr-awaiting', color: 'orange', filter: 'open'     },
  { identifier: 'vr-rejected',        path: 'vr-list',               icon: 'icon-circle-cross',      title: 'Abgelehnter Urlaub',           isInternal: true, dashboardTitle: 'Abgelehnter Urlaub',                           counter: 'vr-rejected', color: 'red',    filter: 'rejected' },

  { identifier: 'vr-approved',        path: 'vr-list',               icon: 'icon-circle-tick',       title: 'Bestätigt Urlaub',             isCustomer: true, dashboardTitle: 'Bestätigt Urlaub',                             counter: 'vr-approved', color: 'green',  filter: 'approved' },
  { identifier: 'vr-open',            path: 'vr-list',               icon: 'icon-circle-time',       title: 'Offen Urlaub ',                isCustomer: true, dashboardTitle: 'Offen Urlaub ',                                counter: 'vr-awaiting', color: 'orange', filter: 'open'     },
  { identifier: 'vr-rejected',        path: 'vr-list',               icon: 'icon-circle-cross',      title: 'Nicht bestätigt Urlaub',       isCustomer: true, dashboardTitle: 'AbgelehNicht bestätigtnter Urlaub',            counter: 'vr-rejected', color: 'red',    filter: 'rejected' },

  { identifier: 'vr-failed-erp',      path: 'failed-erp?tab=vr',     icon: 'icon-warning',           title: 'Fehlerhafte Übertragungen',    isInternal: true, dashboardTitle: 'Fehlerhafte Übertragungen',                    counter: 'vr-failed',   color: 'orange', extra: true        },
  { identifier: 'vr-new',             path: 'vr-new',                icon: 'icon-holiday-add',       title: 'Urlaubsantrag erstellen',      isInternal: true, dashboardTitle: 'Urlaubsantrag erstellen' },
  ]},

  { identifier: 'companies-list',     path: 'companies-list',        icon: 'icon-users',             title: 'Kunden',                       isInternal: true, dashboardTitle: 'Kunden',                                                                                                                        sortOrderByDefault: [true] },
  { identifier: 'photo-doc',          path: 'pd-list',               icon: 'icon-picture',           title: 'Fotodokument',                 isInternal: true, dashboardTitle: "Fotodokument",                                 counter: 'photo',       color: 'blue', sortByDefault: ['read_at', 'created_at'], sortOrderByDefault: [true] },
  { identifier: 'ebs-list',           path: 'ebs-list',              icon: 'icon-user',              title: 'Mitarbeiter',                  isInternal: true, dashboardTitle: 'Einsatzbegleitscheine',                        counter: 'ebs',         color: 'blue', sortByDefault: ['ebs_id']                                            },
  { identifier: 'mm-list',            path: 'mm-list',               icon: 'icon-car',               title: 'Kilometergeldberichte',        isInternal: true, dashboardTitle: 'Kilometergeldberichte',                        counter: 'mm-open',     color: 'blue', betaFlag: 'standalone_mileage_reports'                               },

  { identifier: 'ebs-list',           path: 'assignments-list',      icon: 'icon-user',              title: 'Mitarbeiter',                  isCustomer: true, dashboardTitle: 'Mitarbeiter',                                  counter: 'ebs',         color: 'blue', sortByDefault: ['ends_at'],               sortOrderByDefault: [true] },
  { identifier: 'assignments-export', path: 'assignments-export',    icon: 'icon-excel',             title: 'Reporting',                    isCustomer: true, dashboardTitle: 'Reporting'                                                                                                                                                 },

  { identifier: 'archive',            path: 'search-archiv',         icon: 'icon-archive',           title: 'Archiv',                                         dashboardTitle: 'Archiv', children: [
  { identifier: 'archive-ar',         path: 'search-archiv?tab=ar',  icon: 'icon-time-tracking',     title: 'Tätigkeitsnachweis',                             dashboardTitle: 'Archiv'                                         },
  { identifier: 'archive-pd',         path: 'search-archiv?tab=pd',  icon: 'icon-picture',           title: 'Fotodokument',                 isInternal: true, dashboardTitle: 'Archiv',                                        },
  { identifier: 'archive-ebs',        path: 'search-archiv?tab=ebs', icon: 'icon-user',              title: 'Einsatzbegleitscheine',        isInternal: true, dashboardTitle: 'Archiv',                                        },
  { identifier: 'archive-vr',         path: 'search-archiv?tab=vr',  icon: 'icon-holiday',           title: 'Urlaubsanträge',                                 dashboardTitle: 'Archiv', betaFlag: 'vacation_requests'          },
  { identifier: 'archive-mm',         path: 'search-archiv?tab=mm',  icon: 'icon-car',               title: 'Kilometergeldberichte',        isInternal: true, dashboardTitle: 'Archiv', betaFlag: 'standalone_mileage_reports' }
  ]},

  { identifier: 'logout',                                            icon: 'icon-exit',              title: 'Logout',                                                                                                                               bottom: true },
  { identifier: 'hilfe',                                             icon: 'icon-help',              title: 'Hilfe',                        isCustomer: true, externalPath: 'https://www.tempton.de/downloads/Tempton_Broschuere_Kundenportal.pdf', bottom: true },
  { identifier: 'hilfe',                                             icon: 'icon-help',              title: 'Erste Hilfe & Anleitung',      isInternal: true, externalPath: 'https://akademie.tempton.net/sachbearbeiter-verwaltung',               bottom: true },
  { identifier: 'releases',           path: 'releases',              icon: 'icon-releases',          title: 'Releases',                                       dashboardTitle: 'Releases', sortByDefault: ['released_at'],                           bottom: true },
];
