import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarRouteExtended } from '@shared/models';
import { collapse } from 'app/animations';

@Component({
  selector:      'sidebar-item',
  templateUrl: './sidebar-item.component.html',
  animations: [ collapse ],
  host: { class: 'overflow-visible' }
})
export class SidebarItemComponent {
  @Input() item:            SidebarRouteExtended;
  @Input() activeTab:       SidebarRouteExtended;
  @Input() sidebarMinified: boolean;
  @Input() getReportsCount: Function;

  @Output() callback = new EventEmitter<any>();
  constructor() {}

  callbackHandler(): void {
    this.callback.emit(this.item);
  }

  isActive(item: SidebarRouteExtended): boolean {
    if (item?.identifier === 'archive' && this.activeTab?.identifier?.includes('archive')) return true;
    return this.activeTab?.identifier === item?.identifier;
  }

  toggleSection(e: Event, item: SidebarRouteExtended): void {
    e.stopPropagation();
    item.collapse = !item.collapse;
  }

  childClick(e: Event): void {
    e.stopPropagation();
  }

  getCounterClass(item: SidebarRouteExtended): string {
    let className: string = '';
    if ( this.sidebarMinified) className = 'absolute right-0 circle-xs bg-orange';
    if (!this.sidebarMinified) className = 'text-center text-xs width-40 b-table-grey';
    return !this.sidebarMinified ? className + ` bg-${item.color || 'grey'} color-${item.color ? 'white' : 'orange'}` : className;
  }

}