import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NotificationService, SearchHistoriesService, SessionStorageService, ToasterService} from '@shared/services';
import {SubscriptionLike} from 'rxjs';
import {SearchTermHistoryModel} from '@shared/models';

@Component({
  selector: 'pagination-search',
  templateUrl: 'pagination-search.component.html',
  styleUrls: ['./pagination-search.component.sass'],
})
export class PaginationSearchComponent implements OnInit, OnDestroy {
  @Input() tooltipItems: string[];
  @Output() callback = new EventEmitter<any>();
  subscriptions: SubscriptionLike[] = [];
  searchValue: string;
  suggestions: SearchTermHistoryModel[] = [];
  showSuggestions = false;

  constructor(
    private notificationService: NotificationService,
    private sessionStorageService: SessionStorageService,
    public toasterService: ToasterService,
    private searchHistoriesService: SearchHistoriesService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.searchValue.subscribe((query) => this.searchValue = query));
    this.subscriptions.push(this.searchHistoriesService.searchTermHistoriesObserver.subscribe((terms) => {
      this.suggestions = terms.slice(0, 5);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  onSearchValueChange(query: string): void {
    this.searchHistoriesService.searchTermsStartingWith(query);
    this.showSuggestions = true;
  }

  clearSearch(): void {
    this.searchValue = '';
    this.updateWithSearch(this.searchValue);
    this.clearSuggestions();
  }

  onSearchValueFocusIn(query: string): void {
    this.searchHistoriesService.searchTermsStartingWith(query);
    this.showSuggestions = true;
  }

  onSearchValueFocusOut(): void {
    setTimeout(() => this.showSuggestions = false, 500);
  }

  onSearchValueKeydown(event: any): void {
    this.showSuggestions = true;
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.searchValue = event.target.value;
      this.updateWithSearch(this.searchValue);
      this.clearSuggestions();
    }
  }

  pickSuggestion(term: SearchTermHistoryModel) {
    this.searchHistoriesService.hitSearchHistory(term);
    this.searchValue = term.search_term;

    this.clearSuggestions();
    this.updateWithSearch(this.searchValue);
  }

  deleteSuggestion(event: any, term: SearchTermHistoryModel): void {
    event.stopPropagation();
    this.searchHistoriesService.deleteSearchHistory(term);
  }

  private updateWithSearch(query: string): void {
    this.notificationService.wait();
    this.sessionStorageService.changeSearchValue(query.trim());
    this.sessionStorageService.changePageNumber(1);
    this.callback.emit();
  }

  private clearSuggestions() {
    this.showSuggestions = false;
    this.suggestions = [];
  }

}
