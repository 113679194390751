import { Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormatDatesService } from '@shared/services';
import { AssignmentExtended, TimeFrame } from '@shared/factories';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';

@Component({
  selector: 'assignments-table',
  templateUrl: '../../table-entries.component.html',
  styleUrls: [
    './assignments-table.component.sass',
    './time-frames-table.component.sass'
  ],
})
export class AssignmentsTableComponent {
  @Input() rows:         (AssignmentExtended | TimeFrame)[];
  @Input() paginationId: string;

  @Input() timeFormat:   string;
  @Output() headerCallback         = new EventEmitter<any>();
  @Output() checkboxCallback       = new EventEmitter<TimeFrame>();
  @Output() headerCheckboxCallback = new EventEmitter<any>();
  constructor(private formatDatesService: FormatDatesService) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    if      (this.paginationId === 'assignment-list')   return 'assignments-table';
    else if (this.paginationId === 'assignment-export') return 'time-frames-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.rows && (changes.rows.firstChange || JSON.stringify(changes.rows.currentValue) !== JSON.stringify(changes.rows.previousValue))) this.collapseFirstAssignment();
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    if      (this.paginationId === 'assignment-list')   return this.prepareAssignmentsListTableHeader();
    else if (this.paginationId === 'assignment-export') return this.prepareAssignmentsExportTableHeader();
  }

  private prepareAssignmentsListTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: ' ',           class: 'arrow-cell'                              },
      { title: 'Mitarbeiter', class: 'name-cell',   sort_by: ['external_name'] },
      { title: 'Einsätze',    class: 'title-cell',  sort_by: ['title']         },
      { title: 'Status',      class: 'status-cell'                             },
      { title: 'Einsatzende', class: 'date-cell',   sort_by: ['ends_at']       },
      { title: 'Aktion',      class: 'action-cell'                             }
    ];
  }

  private prepareAssignmentsExportTableHeader(): TableHeaderEntryModel[] {
    return ([
      { title: ' ',                            class: 'checkbox-cell'            },
      { title: 'Mitarbeiter',                  class: 'name-cell'                },
      { title: 'Einsätze',                     class: 'title-cell'               },
      { title: 'Zeitraum',                     class: 'week-cell'                },
      { title: 'Status',                       class: 'status-cell'              },
      { title: 'Datum',                        class: 'day-cell'                 },
      { title: 'Arbeitszeit',                  class: 'hours-cell'               },
      { title: 'Pausen',                       class: 'pauses-cell'              },
      { title: 'Gesamtstunden',                class: 'total-time-cell'          },
      { title: 'Arbeitsstunden abzgl. Pausen', class: 'total-time-no-pause-cell' }
    ] as TableHeaderEntryModel[]).map((c, index) => {
      if (index) c.input = { class: 'custom-checkbox blue-border small', type: 'checkbox', active: true, click: () => this.headerCheckboxCallbackHandler(c, index)};
      return c;
    });
  }

  prepareTableBody(): TableBodyEntryModel[] {
    if      (this.paginationId === 'assignment-list')   return this.prepareAssignmentsListTableBody();
    else if (this.paginationId === 'assignment-export') return this.prepareAssignmentsExportTableBody();
  }

  private prepareAssignmentsListTableBody(): TableBodyEntryModel[] {
    return (this.rows as AssignmentExtended[]).map((assignment: AssignmentExtended): TableBodyEntryModel => ({
      cells: [
        { value: ' ',              class: `xs-hide arrow-cell`, valueClass: 'am-flex-center font-icon hover-active icon-arrow-down', click: (event) => this.collapseAssignment(assignment, event) },
        { xs_label: 'Mitarbeiter', class: 'am-flex-column am-flex-justify-center relative am-flex-align-self-stretch name-cell', valueClass: 'am-flex-align am-flex-1', value: assignment.external_employee && assignment.external_employee.nameReverse || 'n.a', children: [
          { class: 'am-flex-column am-flex-justify-end gap10 xs-hide assignment-details-box', children: [
            { value: 'Equal Pay' },
            { value: assignment.equal_pay ? formatDate(assignment.equal_pay, 'dd.MM.yyyy', 'de') : '-', class: this.checkExpiryDate(assignment.equal_pay) ? 'color-red' : '' }
          ]},
          { class: 'web-tooltip bottom-left short red hide', children: [
            { class: 'tooltip-regular', value: this.getInnerValueStateText(assignment.equal_pay) }
          ]}
        ]},
        { xs_label: 'Einsätze', class: 'am-flex-column am-flex-justify-center relative am-flex-align-self-stretch title-cell', valueClass: 'am-flex-align am-flex-1', value: assignment.title || '-', children: [
          { class: 'am-flex-column am-flex-justify-end gap10 xs-hide assignment-details-box', children: [
            { value: 'Höchstüberlassungsdauer' },
            { value:  assignment.max_rental_period ? formatDate(assignment.max_rental_period, 'dd.MM.yyyy', 'de') : '-', class: this.checkExpiryDate(assignment.max_rental_period) ? 'color-red' : '' },
            { class:  'web-tooltip bottom-left short red hide', children: [
              { class: 'tooltip-regular', value: this.getInnerValueStateText(assignment.max_rental_period) }
            ]}
          ]}
        ]},
        { class: 'status-cell xs-hide', children: [
          { class: `am-flex-center font-icon color-${this.getAssignmentState(assignment)} ${this.getAssignmentStateIcon(assignment)}`, value: ' ', click: (event) => this.collapseAssignment(assignment, event) },
          { class: `web-tooltip left short ${this.getAssignmentState(assignment)}`, children: [
            { class: 'tooltip-regular', value: this.checkExpiredAssignmentText(assignment) }
          ]}
        ]},
        { xs_label: 'Einsatzende',              class: 'date-cell', children: [
          { class: 'am-flex-align assignment-end-date-box', children: [
            { class: `assignment-end-date ${this.checkExpiryDate(assignment.ends_at) ? 'color-red' : ''}`, value: formatDate(assignment.ends_at,'dd.MM.yyyy', 'de') },
            { class: 'web-tooltip left short red', skip: !this.checkExpiryDate(assignment.ends_at), children: [
              { class: 'tooltip-regular', value: this.getValueStateText(assignment.ends_at) }
            ]}
          ]}
        ]},
        { xs_label: 'Equal Pay',                class: 'xs-show date-cell', valueClass: this.checkExpiryDate(assignment.equal_pay)         ? 'color-red' : '' , value: formatDate(assignment.equal_pay,         'dd.MM.yyyy', 'de') },
        { xs_label: 'Höchstüber lassungsdauer', class: 'xs-show date-cell', valueClass: this.checkExpiryDate(assignment.max_rental_period) ? 'color-red' : '' , value: formatDate(assignment.max_rental_period, 'dd.MM.yyyy', 'de') },
        { xs_label: '',                         class: 'action-cell',       xs_icons: [{ class: `color-${this.getAssignmentState(assignment)} ${this.getAssignmentStateIcon(assignment)}` }],
          buttons: [{
            class: `action-cell-button ${this.checkExpiredAssignment(assignment) ? 'blue' : 'grey'}`,
            label: 'Anfragen',
            routerLink: ['/time-tracking/assignment-extend', assignment.id]
          }]
        },
      ]
    }));
  }

  private collapseFirstAssignment(): void {
    if (this.rows.length) setTimeout(() => {
      this.collapseAssignment(this.rows[0] as AssignmentExtended);
    });
  }

  private collapseAssignment(assignment: AssignmentExtended, e = null): void {
    let row = e ? e.target.closest('.list-row') : document.getElementsByClassName('list-row')[0];
    row.classList.toggle('collapsed');
    let detailsBoxes = row.getElementsByClassName('web-tooltip');
    if (this.checkExpiryDate(assignment.equal_pay)) detailsBoxes[0].classList.toggle('hide');
    if (this.checkExpiryDate(assignment.max_rental_period)) detailsBoxes[1].classList.toggle('hide');
  }

  private getAssignmentState(assignment: AssignmentExtended): string {
    if (this.checkExpiryDate(assignment.ends_at) || this.checkExpiryDate(assignment.max_rental_period)) return 'red'
    else if (this.checkExpiryDate(assignment.equal_pay)) return 'orange';
    return 'green';
  }

  private getAssignmentStateIcon(assignment: AssignmentExtended): string {
    if (this.getAssignmentState(assignment) === 'green') return 'icon-circle-tick';
    return 'icon-warning';
  }

  private checkExpiredAssignmentText(assignment: AssignmentExtended): string {
    let state = this.getAssignmentState(assignment);
    if (state === 'green')  return 'Keine Ereignisse in naher Zukunft.';
    if (state === 'orange') return 'Equal Pay Zeitpunkt bald erreicht.';
    if (state === 'red')    return 'Bitte Einsatzdetails prüfen.';
  }

  private getValueStateText(date: Date): string {
    return date && date.getTime() <= new Date().getTime() ? 'Dieses Datum ist bereits überschritten.' : 'Dieser Einsatz endet bald.';
  }

  private getInnerValueStateText(date: Date): string {
    return date && date.getTime() <= new Date().getTime() ? 'Dieses Datum ist bereits überschritten.' : 'Dieses Datum ist bald erreicht.';
  }

  private checkExpiredAssignment(assignment: AssignmentExtended): boolean {
    if (this.checkExpiryDate(assignment.ends_at))           return true;
    if (this.checkExpiryDate(assignment.equal_pay))         return true;
    if (this.checkExpiryDate(assignment.max_rental_period)) return true;
    return false;
  }

  private checkExpiryDate(endDate: Date): boolean {
    return endDate ? (endDate.getTime() - new Date().getTime()) < (4 * 7 * 24 * 60 * 60 * 1000) : null;
  }

  private prepareAssignmentsExportTableBody(): TableBodyEntryModel[] {
    return (this.rows as TimeFrame[]).map((time_frame: TimeFrame): TableBodyEntryModel => ({
      cells: [
        { class: 'checkbox-cell', click: () => this.checkboxCallbackHandler(time_frame),
          input: {
            class: 'custom-checkbox blue big',
            type: 'checkbox',
            id: time_frame.id,
            active: time_frame.active,
          }
        },
        { xs_label: 'Mitarbeiter', class: 'name-cell',   value: time_frame.external_employee && time_frame.external_employee.nameReverse || 'n.a' },
        { xs_label: 'Einsätze',    class: 'title-cell',  value: time_frame.assignment_title       },
        { xs_label: 'Zeitraum',    class: 'week-cell',   children: [{
          class: 'am-flex-column', children: [
            { value: time_frame.calendar_week },
            { value: time_frame.date          }
          ]}
        ]},
        { class: 'status-cell xs-hide', children: [
          { class: `status-icon ${this.getStatusDotColor(time_frame)}`},
          { class: `web-tooltip left short ${this.getStatusDotColor(time_frame)}`, children: [
            { class: "tooltip-title", value: this.getStatusDotText(time_frame)}
          ]}
        ]},
        { xs_label: 'Datum',       class: 'day-cell',    children:[{
          class: 'am-flex-column', children: [
            { value: formatDate(time_frame.started_at, 'EEEE', 'de')       },
            { value: formatDate(time_frame.started_at, 'dd.MM.yyyy', 'de') }
          ]}
        ]},
        { xs_label: 'Arbeitszeit',    class: 'hours-cell',  value: `${formatDate(time_frame.started_at, 'HH:mm', 'de')} - ${formatDate(time_frame.ended_at, 'HH:mm', 'de')} Uhr` },
        { xs_label: 'Pausen',         class: 'am-flex-column pauses-cell', children: time_frame.pauses.map(p => (
          { value: `${formatDate(p.start, 'HH:mm', 'de')} - ${formatDate(p.end, 'HH:mm', 'de')} Uhr` }
        ))},
        { xs_label: 'Gesamtstunden',  class: 'total-time-cell',  value: this.formatDate(time_frame.totalDuration)                                         },
        { xs_label: 'Arbeitsstunden abzgl. Pausen',  class: 'total-time-no-pause-cell',  value: this.formatDate(time_frame.totalDurationExcludingPauses)  },
        { xs_label: '', class: 'action-cell',
          xs_icons: [
            { class: `icon-tick ${time_frame.active ? '' : 'hide'}`  },
            { class: `status-icon ${this.getStatusDotColor(time_frame)}` }
          ],
          buttons: [{
            class: 'action-cell-button',
            label: time_frame.active ? 'Entfernen' : 'Markieren',
            click: () => this.checkboxCallbackHandler(time_frame)
          }]
        }
      ]
    }));
  }

  private checkboxCallbackHandler(time_frame: TimeFrame): void {
    this.checkboxCallback.emit(time_frame);
  }

  private headerCheckboxCallbackHandler(...res): void {
    this.headerCheckboxCallback.emit(res);
  }

  private formatDate(date) {
    if (this.timeFormat === 'industrial') return this.formatDatesService.formatDateIndustrial(date);
    else if (this.timeFormat === 'real-time') return this.formatDatesService.formatDateRealTime(date);
  }

  private getStatusDotColor(time_frame: TimeFrame): string {
    if (!!time_frame.working_period_approved_at) return 'green';
    return 'orange';
  }

  private getStatusDotText(time_frame: TimeFrame): string {
    if (!!time_frame.working_period_approved_at) return 'TN freigegeben';
    return 'TN in Bearbeitung';
  }

}
