import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, combineLatest, debounceTime, firstValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {SearchTermHistoryModel} from '@shared/models';
import {QueryCollectorService} from '@shared/services';


interface SearchTermHistoryResponse {
  search_term_histories: SearchTermHistoryModel[];
}

@Injectable({
  providedIn: 'root'
})

export class SearchHistoriesService {

  SEARCH_TERM_HISTORIES_API: string = `api/portal/v3/search_term_histories`;
  private searchTermHistoryMapSubject = new BehaviorSubject<Map<string, SearchTermHistoryModel>>(new Map());
  private termSubject = new BehaviorSubject<string>('');

  searchTermHistoriesObserver = combineLatest([
    this.searchTermHistoryMapSubject.asObservable(),
    this.termSubject.pipe(debounceTime(300))
  ]).pipe(map(([terms, term]) => {
    return Array.from(terms.values()).filter(t => t.search_term.startsWith(term)).sort((a, b) => b.hit - a.hit);
  }));

  constructor(
    private http: HttpClient,
    private queryCollectorService: QueryCollectorService
  ) {
    this.SEARCH_TERM_HISTORIES_API = `${environment.apiUrl}${this.SEARCH_TERM_HISTORIES_API}`;
    // init cache
    this.searchTermsStartingWith('');
  }

  /*
  * always fetch history from server with query param
  * and update searchTermHistoryMap
  * */
  searchTermsStartingWith(staringWith: string): void {

    // update term
    this.termSubject.next(staringWith);

    // build query param
    const queryParam = this.queryCollectorService.getSearchTermHistory(staringWith);
    // fetch history with query param
    firstValueFrom(this.http.get<SearchTermHistoryResponse>(`${this.SEARCH_TERM_HISTORIES_API}${queryParam}`).pipe(
      map(res => {
        return res.search_term_histories || [];
      })
    )).then(terms => {
      const next = this.searchTermHistoryMapSubject.value;
      for (const term of terms) {
        next.set(term.id, term);
      }
      this.searchTermHistoryMapSubject.next(next);
    });
  }

  hitSearchHistory(term: SearchTermHistoryModel): void {
    firstValueFrom(this.http.put(`${this.SEARCH_TERM_HISTORIES_API}/${term.id}`, {})).catch(err => console.error(err));
  }

  deleteSearchHistory(term: SearchTermHistoryModel): void {
    // delete local
    const next = this.searchTermHistoryMapSubject.value;
    next.delete(term.id);
    this.searchTermHistoryMapSubject.next(next);
    // delete remote
    firstValueFrom(this.http.delete(`${this.SEARCH_TERM_HISTORIES_API}/${term.id}`)).catch(err => console.error(err));
  }

}
