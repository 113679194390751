import { Injectable, Injector     } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { Title                    } from "@angular/platform-browser";
import { BehaviorSubject, filter, pairwise } from "rxjs";

import { environment } from '../../environments/environment';

import {
  SidebarRouteExtended,
  SortingOptionModel,
  BannerModel,
  ActivityReportsCountersModel,
  EBSCounterResponseModel,
  AssignmentsCounterResponseModel,
  InvoiceCountersResponseModel,
  VacationRequestsCountersModel,
  HeaderFiltersModel,
  HeaderTogglersModel,
  TogglerModel,
  MileageMoneyCountersModel,
  PillModel
} from "@shared/models";

import { AutoReloadService     } from "./auto-reload.service";
import { SidebarStorageService } from "./sidebar-storage.service";
import { UserService           } from "./auth/user.service";

const RECENT_PILLS_TABLE_NAME     = 'recentPills';
const PASSED_TUTORIALS_TABLE_NAME = 'passedTutorials';
const PASSED_RELEASES_TABLE_NAME  = 'passedReleases';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private activityReportsArchiveFormValues:  object;
  private photoDocumentsArchiveFormValues:   object;
  private ebsArchiveFormValues:              object;
  private vacationRequestsArchiveFormValues: object;
  private mileageMoneyArchiveFormValues:     object;
  private failedERPDateFiltersValues:        object;
  private reloadAvailableState:              boolean;

  private activeAppSource = new BehaviorSubject<string>('time-tracking');
  activeApp = this.activeAppSource.asObservable();

  private activeTabSource = new BehaviorSubject<SidebarRouteExtended>(null);
  activeTab = this.activeTabSource.asObservable();

  private itemsPerPageSource = new BehaviorSubject<number>(10);
  private pageNumberSource   = new BehaviorSubject<number>(1);
  private totalCountSource   = new BehaviorSubject<number>(1);
  private totalPagesSource   = new BehaviorSubject<number>(1);
  
  itemsPerPage = this.itemsPerPageSource.asObservable();
  pageNumber   = this.pageNumberSource.asObservable();
  totalCount   = this.totalCountSource.asObservable();
  totalPages   = this.totalPagesSource.asObservable();

  private sortingFlowSource = new BehaviorSubject<SortingOptionModel[]>(null);
  sortingFlow = this.sortingFlowSource.asObservable();

  private searchValueSource = new BehaviorSubject<string>('');
  searchValue = this.searchValueSource.asObservable();

  private bannersSource = new BehaviorSubject<BannerModel[]>(null);
  banners = this.bannersSource.asObservable();

  private pillsSource = new BehaviorSubject<PillModel[]>(null);
  pills = this.pillsSource.asObservable();

  private dynamicComponentSource = new BehaviorSubject<any>(null);
  dynamicComponent = this.dynamicComponentSource.asObservable();

  private activityReportsCountersTokensSource       = new BehaviorSubject<any>(null);
  private photoDocumentsCountersTokensSource        = new BehaviorSubject<any>(null);
  private ebsCountersTokensSource                   = new BehaviorSubject<any>(null);
  private assignmentsCountersTokensSource           = new BehaviorSubject<any>(null);
  private invoicesCountersTokensSource              = new BehaviorSubject<any>(null);
  private vacationRequestsCountersTokensSource      = new BehaviorSubject<any>(null);
  private mileageMileageReportsCountersTokensSource = new BehaviorSubject<any>(null);

  private headerFiltersSource = new BehaviorSubject<HeaderFiltersModel>({
    ebs: [
      { label: 'Bestätigt',   field: 'ebsConfirmed',                },
      { label: 'Unbestätigt', field: 'ebsUnConfirmed', active: true },
    ],
    failed_erp: [
      { label: 'Offen',    field: 'zvooveImportFailed',           active: true },
      { label: 'Erledigt', field: 'zvooveImportResolvedManually'               },
    ]
  });
  headerFilters = this.headerFiltersSource.asObservable();

  private headerTogglersSource = new BehaviorSubject<HeaderTogglersModel>({
    wp_details: [
      { label: 'Echtzeit',        value: 'real-time', active: true },
      { label: 'Industriezeit',   value: 'industrial'              }
    ],
    // vacations: [
    //   { label: 'Klärungsbedarf',  value: 'rejected', icon: 'icon-circle-cross color-red',    isInternal: true },
    //   { label: 'Freigegeben',     value: 'approved', icon: 'icon-circle-tick  color-green',  isInternal: true },
    //   { label: 'Ausstehend',      value: 'open',     icon: 'icon-circle-time  color-orange', isInternal: true },

    //   { label: 'Offen',           value: 'open',     icon: 'icon-circle-time  color-orange', isCustomer: true },
    //   { label: 'Nicht bestätigt', value: 'rejected', icon: 'icon-circle-cross color-red',    isCustomer: true },
    //   { label: 'Bestätigt',       value: 'approved', icon: 'icon-circle-tick  color-green',  isCustomer: true }
    // ],
    invoice: [
      { label: 'Dashboard',       value: 'dashboard' },
      { label: 'Archiv',          value: 'archive'   }
    ],
    failed_erp: [
      { label: 'TN', value: 'ar', icon: 'icon-time-tracking', active: true              },
      { label: 'UA', value: 'vr', icon: 'icon-holiday',       beta: 'vacation_requests' }
    ],
    archive: [
      { label: 'Tätigkeitsnachweis',    labelMin: 'TN',  value: 'ar',  icon: 'icon-time-tracking', active:     true                   },
      { label: 'Fotodokument',          labelMin: 'FD',  value: 'pd',  icon: 'icon-picture',       isInternal: true                   },
      { label: 'Einsatzbegleitscheine', labelMin: 'EBS', value: 'ebs', icon: 'icon-user',          isInternal: true                   },
      { label: 'Urlaubsanträge',        labelMin: 'UA',  value: 'vr',  icon: 'icon-holiday',       beta: 'vacation_requests'          },
      { label: 'Kilometergeldberichte', labelMin: 'KG',  value: 'mm',  icon: 'icon-car',           isInternal: true,
                                                                                                   beta: 'standalone_mileage_reports' },
    ],
  });
  headerTogglers = this.headerTogglersSource.asObservable();

  buildHashValue:    any;
  passedTutorials:   any;

  prevUrl:           string;
  currentUrl:        string;
  redirectUrl:       string;

  latestCreatedByMe: number[];
  failedResolved:    string;
  constructor (
    private injector:              Injector,
    private router:                Router,
    private titleService:          Title,
    private userService:           UserService,
    private sidebarStorageService: SidebarStorageService
  ) { }

  get activityReportsArchiveForm():  object  { return this.activityReportsArchiveFormValues;  }
  get photoDocumentsArchiveForm():   object  { return this.photoDocumentsArchiveFormValues;   }
  get ebsArchiveForm():              object  { return this.ebsArchiveFormValues;              }
  get vacationRequestsArchiveForm(): object  { return this.vacationRequestsArchiveFormValues; }
  get mileageMoneyArchiveForm():     object  { return this.mileageMoneyArchiveFormValues;     }
  get failedERPDateFilters():        object  { return this.failedERPDateFiltersValues;        }
  get reloadAvailable():             boolean { return this.reloadAvailableState;              }
  get buildHash():                   object  { return this.buildHashValue;                    }

  set activityReportsArchiveForm( data:  object ) { this.activityReportsArchiveFormValues  = data;  }
  set photoDocumentsArchiveForm(  data:  object ) { this.photoDocumentsArchiveFormValues   = data;  }
  set ebsArchiveForm(             data:  object ) { this.ebsArchiveFormValues              = data;  }
  set vacationRequestsArchiveForm(data:  object ) { this.vacationRequestsArchiveFormValues = data;  }
  set mileageMoneyArchiveForm(    data:  object ) { this.mileageMoneyArchiveFormValues     = data;  }
  set failedERPDateFilters(       data:  object ) { this.failedERPDateFiltersValues        = data;  }
  set reloadAvailable(            state: boolean) { this.reloadAvailableState              = state; }
  set buildHash(                  data:  object ) { this.buildHashValue                    = data;  }

  changeAndResetActiveTab(identifier: string): void {
    let filterItem = this.findSidebarItem(identifier);
    this.changeItemsPerPage(filterItem?.paginationNumber || 10);
    this.changePageNumber(1);
    this.changeSortingFlow([{
      columns: filterItem.sortByDefault      || ['created_at'],
      order:   filterItem.sortOrderByDefault || [false]
    }]);
    this.changeSearchValue('');
    this.collapseSidebarSection(filterItem);
    this.changeActiveTab(identifier);
    this.redirectUrl = null;
  }

  changeActiveTab(identifier: string): void {
    let filterItem = this.findSidebarItem(identifier);
    this.titleService.setTitle(filterItem.title);
    this.activeTabSource.next(filterItem);
  }

  private findSidebarItem(identifier: string): SidebarRouteExtended {
    let items      = this.sidebarStorageService.sidebarItems.filter(i => i.identifier);
    let children   = this.sidebarStorageService.sidebarItems.filter(i => i.children).reduce((sum, i) => {
      let children = [...i.children];
      children.forEach(c => c.parent = i.identifier);
      sum = [...sum, ...children];
      return sum
    }, []);
    return [...items, ...children].find(item => item.identifier === identifier);
  }

  private collapseSidebarSection(item: SidebarRouteExtended): void {
    if (item.parent) {
      let parent = this.findSidebarItem(item.parent);
      parent.collapse = true
    }
  }

  get activeAppValue(): string {
    return this.activeAppSource.value;
  }

  setActiveApp(app: string = null): void {
    if (app) this.changeActiveApp(app);
    else if (this.router.url.includes(`/invoices/`)) this.changeActiveApp('invoices');
    else this.changeActiveApp('time-tracking');
  }

  changeActiveApp(app: string): void {
    let autoReloadService = this.injector.get(AutoReloadService)
    setTimeout(() => autoReloadService.resetAutoReload(app));
    this.sidebarStorageService.changeSidebar(app);
    this.activeAppSource.next(app);
    this.userService.setPills(this.userService.currentUserValue);
  }

  get isTimeTracking(): boolean {
    return this.activeAppSource.value === 'time-tracking';
  }

  get isInvoices(): boolean {
    return this.activeAppSource.value === 'invoices';
  }

  get activeTabValue(): SidebarRouteExtended {
    return this.activeTabSource.value;
  }

  get itemsPerPageValue(): number { return this.itemsPerPageSource.value; }
  get pageNumberValue():   number { return this.pageNumberSource.value;   }
  get pageTotalCount():    number { return this.totalCountSource.value;   }
  get pagesTotal():        number { return this.totalPagesSource.value;   }

  changeItemsPerPage(number: number): void { this.itemsPerPageSource.next(number); }
  changePageNumber(  number: number): void { this.pageNumberSource.next(number);   }
  changeTotalCount(  number: number): void { this.totalCountSource.next(number);   }
  changeTotalPages(  number: number): void { this.totalPagesSource.next(number);   }

  changeSortingFlow(sorts: SortingOptionModel[]): void {
    this.sortingFlowSource.next(sorts);
  }

  get sortingFlowValue(): SortingOptionModel[] {
    return this.sortingFlowSource.value;
  }

  changeSearchValue(query: string): void {
    this.searchValueSource.next(query);
  }

  get searchValueValue(): string {
    return this.searchValueSource.value;
  }

  changeBanners(banners: BannerModel[]): void {
    this.bannersSource.next(banners);
  }

  setActiveCompany(pill: PillModel): void {
    let pills = this.pillsValue.map(p => {
      if (p.label === pill.label) p.active = true;
      else p.active = false; 
      return p;
    });

    this.updatePills(pills);
  }

  preparePills(pills: PillModel[]): void {
    let local: PillModel[] = this.loadPillsLocalTable();
    pills.forEach(pill => {
      let saved = local.find(l => l.label === pill.label);
      if (saved) {
        pill.active = saved.active;
        pill.recent = saved.recent;
      }
    });
    this.updatePills(pills);
  }

  updatePills(pills: PillModel[]): void {
    this.updatePillsLocalTable(pills);
    this.pillsSource.next(pills);
  }

  private loadPillsLocalTable(): PillModel[] {
    let table = this.parceLocalTable(RECENT_PILLS_TABLE_NAME) || {};
    return table[this.userService.currentUserValue.id] || [];
  }

  private updatePillsLocalTable(pills: PillModel[]): void {
    let table = this.parceLocalTable(RECENT_PILLS_TABLE_NAME);
    table[this.userService.currentUserValue.id] = pills;
    this.updateLocalTable(RECENT_PILLS_TABLE_NAME, table);
  }

  get pillsValue():    PillModel[] { return this.pillsSource.value;                       }
  get activePills():   PillModel[] { return this.pillsSource.value.filter(p => p.active); }
  get getActivePill(): PillModel   { return this.pillsSource.value.find(p => p.active);   }

  addPill(   pill): void { this.setPill(pill, true);  }
  removePill(pill): void { this.setPill(pill, false); }
  togglePill(pill): void { this.setPill(pill);        }

  private setPill(pill: PillModel, state = null): void {
    let localPill = this.pillsValue.find(p => p.label === pill.label);
    if (localPill) localPill.active = state !== null ? state : !localPill.active;
    this.updatePills(this.pillsValue);
  }

  get isDevice(): boolean {
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))
  }

  changeDynamicComponents(     comp: any):    void {        this.dynamicComponentSource.next(comp);                                    }
  pushDynamicComponent(        item: any):    void { return this.dynamicComponentSource.next(Object.assign(item, { type: 'create' })); }
  popDynamicComponent(         item: string): void { return this.dynamicComponentSource.next({  component: item,   type: 'remove' });  }
  updateDynamicComponentValues(item: any):    void { return this.dynamicComponentSource.next(Object.assign(item, { type: 'update' })); }

  get headerFiltersValue(): any {
    let filters = {...this.headerFiltersSource.value};
    for (const key in filters) {
      filters[key] = filters[key].reduce((sum, val): any => {
        sum[val.field] = val.active;
        return sum;
      }, {});
    }
    return filters;
  }

  changeEBSFilters(field: string): void {
    this.changeHeaderFilters('ebs', field);
  }

  changeFailedERPFilters(field: string): void {
    this.changeHeaderFilters('failed_erp', field);
  }

  private changeHeaderFilters(useCase: string, field: string): void {
    let filters = this.headerFiltersSource.value;
    filters[useCase].map(filter => {
      if (filter.field === field) filter.active = !filter.active;
      return filter;
    });

    this.changePageNumber(1);
    this.changeTotalPages(1);
    this.headerFiltersSource.next(filters);
  }

  get headerTogglersValue(): HeaderTogglersModel {
    return this.headerTogglersSource.value;
  }

  changeWpDetailsTogglers(value: string): void { this.changeHeaderTogglers('wp_details', value);             }
  changeInvoiceTogglers(  value: string): void { this.changeHeaderTogglers('invoice',    value, true);       }
  changeFailedERPTogglers(value: string): void { this.changeHeaderTogglers('failed_erp', value, true);       }
  changeArchiveTogglers(  value: string): void { this.changeHeaderTogglers('archive',    value);             }
  changeVacationsTogglers(value: string): void { this.changeHeaderTogglers('vacations',  value, true, true); }

  private changeHeaderTogglers(useCase: string, value: string, update: boolean = null, allowToDisable: boolean = null): void {
    let togglers: HeaderTogglersModel = this.headerTogglersSource.value;
    togglers[useCase].forEach((toggler: TogglerModel)  => {
      if (toggler.value === value) toggler.active = allowToDisable ? !toggler.active : true;
      else toggler.active = false;
    });

    this.togglersCallback(togglers, update);
  }

  private togglersCallback(togglers: HeaderTogglersModel, update: boolean = null): void {
    if (update) {
      this.changePageNumber(1);
      this.changeTotalPages(1);
    }
    this.headerTogglersSource.next(togglers);
  }

  get activityReportsCountersTokensValue():     ActivityReportsCountersModel    { return this.activityReportsCountersTokensSource.value;       }
  get photoDocumentsCountersTokensValue():      any                             { return this.photoDocumentsCountersTokensSource.value;        }
  get EBSCountersTokensValue():                 EBSCounterResponseModel         { return this.ebsCountersTokensSource.value;                   }
  get assignmentsCountersTokensValue():         AssignmentsCounterResponseModel { return this.assignmentsCountersTokensSource.value;           }
  get invoicesCountersTokensValue():            InvoiceCountersResponseModel    { return this.invoicesCountersTokensSource.value;              }
  get mileageMoneyReportsCountersTokensValue(): MileageMoneyCountersModel       { return this.mileageMileageReportsCountersTokensSource.value; }
  get vacationRequestsCountersTokensValue():    VacationRequestsCountersModel   { return this.vacationRequestsCountersTokensSource.value;      }

  changeActivityReportsCountersTokens(tokens:     ActivityReportsCountersModel):    void { this.activityReportsCountersTokensSource.next(tokens);       }
  changePhotoDocumentsCountersTokens(tokens:      any):                             void { this.photoDocumentsCountersTokensSource.next(tokens);        }
  changeEBSCountersTokens(tokens:                 EBSCounterResponseModel):         void { this.ebsCountersTokensSource.next(tokens);                   }
  changeAssignmentsCountersTokens(tokens:         AssignmentsCounterResponseModel): void { this.assignmentsCountersTokensSource.next(tokens);           }
  changeInvoicesCountersTokens(tokens:            InvoiceCountersResponseModel):    void { this.invoicesCountersTokensSource.next(tokens);              }
  changeMileageMoneyReportsCountersTokens(tokens: MileageMoneyCountersModel):       void { this.mileageMileageReportsCountersTokensSource.next(tokens); }
  changeVacationRequestsCountersTokens(tokens:    VacationRequestsCountersModel):   void { this.vacationRequestsCountersTokensSource.next(tokens);      }

  prepareTutorialsPassedData(): void {
    let passedTutorialsData = {};
    let passedTutorialsList = this.getLocalTableDataByUser(PASSED_TUTORIALS_TABLE_NAME) || [];
    passedTutorialsList.forEach((t: string) => passedTutorialsData[this.normalizeName(t)] = true);

    this.passedTutorials = passedTutorialsData;
  }

  checkTutorialPassed(tutorial: string): boolean {
    let passedTutorialsList = this.getLocalTableDataByUser(PASSED_TUTORIALS_TABLE_NAME) || [];
    return !!passedTutorialsList.find(t => t === tutorial);
  }

  setPassedTutorial(tutorial: string): void {
    this.updateLocalTableAndSave(PASSED_TUTORIALS_TABLE_NAME, tutorial);
    if (tutorial === 'mitarbeiter') this.setActiveApp();
  }

  showTutorial(tutorial: string): void {
    if (!this.passedTutorials[this.normalizeName(tutorial)]) {
      this.passedTutorials[this.normalizeName(tutorial)] = true;
      this.pushDynamicComponent({
        component: 'Tutorial',
        props: { tutorial }
      });
    }
  }

  checkReleasePassed(release) {
    return this.getLocalTableDataByUser(PASSED_RELEASES_TABLE_NAME).includes(`${release.application}_${release.released_at}_${release.id}`);
  }

  setPassedRelease(): void {
    let release, lastRelease = this.userService.latestRelease;;
    let table  = this.parceLocalTable(PASSED_RELEASES_TABLE_NAME);
    let passed = this.getLocalTableDataByUser(PASSED_RELEASES_TABLE_NAME);
    if (lastRelease) release = `${lastRelease.application}_${lastRelease.released_at}_${lastRelease.id}`;
    if (release && !passed.find(p => p === release)) table[this.env][this.userService.currentUserValue.id].push(release);
    this.updateLocalTable(PASSED_RELEASES_TABLE_NAME, table);
    this.setActiveApp();
  }

  private updateLocalTableAndSave(tableName: string, value: string): void {
    let passed = this.parceLocalTable(tableName);
    passed[this.env][this.userService.currentUserValue.id].push(value);
    this.updateLocalTable(tableName, passed);
  }

  private getLocalTableDataByUser(table:string): any[] {
    let temp = this.parceLocalTable(table);
    if (!temp[this.env]) temp[this.env] = {};
    if (!temp[this.env][this.userService.currentUserValue.id]) {
      temp[this.env][this.userService.currentUserValue.id] = [];
      this.updateLocalTable(table, temp);
    }
    return temp[this.env][this.userService.currentUserValue.id];
  }

  private parceLocalTable(table: string): unknown {
    return localStorage.getItem(table) && JSON.parse(localStorage.getItem(table)) || {};
  }

  private updateLocalTable(table: string, data: unknown): void {
    localStorage.setItem(table, JSON.stringify(data));
  }

  get env(): string {
    return environment.envName;
  }

  get production(): boolean {
    return this.env === 'live';
  }

  get usersSettings(): any {
    let usersSettings = JSON.parse(localStorage.getItem('usersSettings')) || {};
    localStorage.setItem('usersSettings', JSON.stringify(usersSettings));
    return usersSettings;
  }

  get currentUserSettings(): any {
    let userSettings = this.usersSettings;
    let userId = this.userService.currentUserValue.id;
    if (!userSettings[userId]) userSettings[userId] = {};
    localStorage.setItem('usersSettings', JSON.stringify(userSettings));
    return userSettings[userId];
  }

  get printOptions() {
    if (this.currentUserSettings.printOptions) this.currentUserSettings.printOptions = {};
    return this.currentUserSettings.printOptions;
  }

  updateCurrentUserSettings(val: any) {
    let userSettings = this.usersSettings;
    userSettings[this.userService.currentUserValue.id] = val;
    localStorage.setItem('usersSettings', JSON.stringify(userSettings));
  }

  updateUserSettings(value, ...path): void {
    this.updateCurrentUserSettings(this.updateUserSettingField(this.currentUserSettings, value, ...path));
  }

  private updateUserSettingField(obj, value, ...path): any {
    if (!obj[path[0]]) obj[path[0]] = {};
    if (path.length > 1) obj[path[0]] = this.updateUserSettingField(obj[path[0]], value, ...path.slice(1));
    else obj[path[0]] = value;
    return obj;
  }

  watchRoutes(): void {
    this.router.events.pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this.prevUrl    = events[0].urlAfterRedirects;
      this.currentUrl = events[1].urlAfterRedirects;
    });
  }

  normalizeName(field: string, postValue: string = null): string {
    let temp  = field.split('-');
    let tempL = temp[0];
    let tempU = temp.slice(1).map(t => this.capitalize(t));
    return `${tempL}${tempU}${postValue}`;
  }

  private capitalize(string: string): string {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  }

}
