import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserService } from '@shared/services';
import {MD5} from 'crypto-js';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  constructor( private userService: UserService ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request.url.includes('logstash')) {
      if (this.userService.currentUserValue) request = this.addDefaultHeaders(request)
    }
    return next.handle(request);
  }

  private addDefaultHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let req = request.clone({ setHeaders: {
      'X-AppName': this.userService.isInternal ? 'TemptonApp Internal' : 'myTempton Kundenportal',
      'X-Locale': 'de'
    }});
    if (request.url.includes('/v3/')) req = req.clone({ setHeaders: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }});
    if (request.url.includes('api/portal/v3')) {
      req = req.clone({
        setHeaders: {
          'X-UUID': this.generateUUID()
        }
      });
    }
    return req;
  }

  private getUserUniqueData(): string {
    const userAgent = navigator.userAgent;
    const screenResolution = `${screen.width}x${screen.height}`;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = navigator.language;
    return `${userAgent}-${screenResolution}-${timeZone}-${language}`;
  }

  private generateUUID(): string {
    const uniqueData = this.getUserUniqueData();
    const hash = MD5(uniqueData).toString();
    return `${hash.substring(0, 8)}-${hash.substring(8, 4)}-4${hash.substring(12, 3)}-${hash.substring(15, 4)}-${hash.substring(19, 12)}`;
  }

}
