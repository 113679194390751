import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Params     } from '@angular/router';
import { formatDate } from '@angular/common';

import { SessionStorageService } from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';
import { ActivityReportMileageMoneyDaily, MileageReportSimplified, StandaloneMileageMoney, StandaloneMileageMoneyDaily } from '@shared/factories';

@Component({
  selector:     'mileage-money-table',
  styleUrls: ['./mileage-money-table.component.sass'],
  templateUrl: '../../table-entries.component.html',
})
export class MileageMoneyTableComponent {
  @Input() rows:          (StandaloneMileageMoney | ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily | MileageReportSimplified)[];
  @Input() paginationId:   string;

  @Input() index:          number;
  @Input() editMode:       boolean;
  @Input() editBox:        string;
  @Input() skipHeader:     boolean;

  @Input() checkIfHoliday: Function;

  @Output() editBoxHandler     = new EventEmitter<any>();
  @Output() valueChangeHandler = new EventEmitter<any>();
  @Output() headerCallback     = new EventEmitter<any>();
  @Output() validate           = new EventEmitter<void>();
  constructor( private sessionStorageService: SessionStorageService ) { }

  headerCallbackHandler(res) {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    if      (this.paginationId === 'mm-list')    return 'mm-list-wrapper';
    else if (this.paginationId === 'archive')    return 'mm-list-wrapper';
    else if (this.paginationId === 'mm-details') return 'mm-details-wrapper';
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    if      (this.paginationId === 'mm-list')    return this.prepareMileageMoneyListTableHeader();
    else if (this.paginationId === 'archive')    return this.prepareMileageMoneyArchiveTableHeader();
    else if (this.paginationId === 'mm-details') return this.prepareMileageMoneyDetailsTableHeader();
  }

  private prepareMileageMoneyTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',     sort_by: ['external_employee_last_name', 'external_employee_first_name'] },
      { title: 'Pers. Nr.',   class: 'pers-num-cell', sort_by: ['personal_number'],                                            },
      { title: 'Einsatz',     class: 'title-cell',    sort_by: ['assignment_title']                                            },
      { title: 'Zeitraum',    class: 'period-cell',   sort_by: ['start_date']                                                  },
      { title: 'Aktion',      class: 'action-cell'                                                                             }
    ];
  }

  private prepareMileageMoneyDetailsTableHeader(): TableHeaderEntryModel[] {
    let header = this.prepareMileageMoneyDetailsBasicTableHeader();
    if (this.rows && this.rows[0] instanceof StandaloneMileageMoneyDaily) header.push({ title: 'Aktion', class: 'action-cell' });
    return header;
  }

  private prepareMileageMoneyDetailsBasicTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: '',                 class: 'holiday-cell'  },
      { title: 'Datum',            class: 'day-cell'      },
      { title: 'Von',              class: 'from-cell'     },
      { title: 'Nach',             class: 'to-cell'       },
      { title: 'Entfernung in km', class: 'total-km-cell' }
    ];
  }

  private prepareMileageMoneyListTableHeader(): TableHeaderEntryModel[] {
    let cols = this.prepareMileageMoneyTableHeader();
    cols.splice(1, 0, { title: 'Eingang', class: 'date-cell', sort_by: ['created_at'] });
    return cols;
  }

  private prepareMileageMoneyArchiveTableHeader(): TableHeaderEntryModel[] {
    let cols = this.prepareMileageMoneyTableHeader();
    cols.splice(1, 0, { title: 'Erstellung',    class: 'date-cell',     sort_by: ['created_at']    });
    cols.splice(2, 0, { title: 'Überprüfung',   class: 'reviewed-cell', sort_by: ['archived_at']   });
    cols.splice(6, 0, { title: 'Typ',           class: 'type-cell',     sort_by: ['resource_type'] });
    return cols;
  }

  prepareTableBody(): TableBodyEntryModel[] {
    if      (this.paginationId === 'mm-list')    return this.prepareMileageMoneyTableBody();
    else if (this.paginationId === 'archive')    return this.prepareMileageMoneyTableBody();
    else if (this.paginationId === 'mm-details') return this.prepareMileageMoneyDetailsTableBody();
  }

  private prepareMileageMoneyTableBody(): TableBodyEntryModel[] {
    return this.rows.map((mm: StandaloneMileageMoney | MileageReportSimplified, index): TableBodyEntryModel => ({
      extra_styles: this.collectSplitReports(this.rows as StandaloneMileageMoney[], mm as StandaloneMileageMoney, index),
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: mm.externalEmployee.nameReverse                                           },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: mm.externalEmployee.personal_number                                       },
        { xs_label: 'Eingang',     class: 'date-cell',     value: formatDate(mm.createdAt,  'dd.MM.yyyy', 'de'),      pageOnly: ['mm-list'] },
        { xs_label: 'Erstellung',  class: 'date-cell',     value: formatDate(mm.createdAt,  'dd.MM.yyyy', 'de'),      pageOnly: ['archive'] },
        { xs_label: 'Überprüfung', class: 'reviewed-cell', value: formatDate(mm.archivedAt, 'dd.MM.yyyy', 'de'),      pageOnly: ['archive'] },
        { xs_label: 'Einsatz',     class: 'title-cell',    value: mm.assignment.title                                                       },
        { xs_label: 'Zeitraum',    class: 'period-cell',   value: `${mm.calendarWeek}\n${mm.date}`                                          },
        { xs_label: 'Typ',         class: 'type-cell',     value: (mm as MileageReportSimplified).resourceTypeMapped, pageOnly: ['archive'] },
        { xs_label: '',            class: 'action-cell',   xs_icons: [],
          buttons: [
            { class: 'action-cell-button',
              label: this.getActionButtonName(mm),
              routerLink: [this.getRedirectUrl(mm)],
              routerLinkState: this.getFastNavigationData()
            }
          ]
        }
      ].filter(c => c.pageOnly && c.pageOnly.filter(p => p).length ? c.pageOnly.filter(p => p).find(p => p === this.paginationId) : true)
    }));
  }

  private collectSplitReports(list: StandaloneMileageMoney[], wp: StandaloneMileageMoney, index: number): string {
    let borders = '', sideBorders = 'bl-grey';
    let topBorder    = 'btl-radius  bt-grey';
    let bottomBorder = 'bbl-radius';

    if (list[index+1]) borders += this.connectedReport(wp, list[index+1]) ? `${sideBorders} ${topBorder} `    : '';
    if (list[index-1]) borders += this.connectedReport(wp, list[index-1]) ? `${sideBorders} ${bottomBorder} ` : '';
    if (list[index+2] && this.connectedReport(list[index+1], list[index+2])) borders += 'bb-none-important'
    return borders;
  }

  private connectedReport(a: StandaloneMileageMoney, b: StandaloneMileageMoney): boolean {
    return a.splitChildId  && b.id === a.splitChildId ||
           a.splitParentId && b.id === a.splitParentId;
  }

  private getActionButtonName(mm: StandaloneMileageMoney | MileageReportSimplified): string {
    return mm.archivedAt ? 'Ansehen' : 'Bearbeiten';
  }

  private getRedirectUrl(mm: StandaloneMileageMoney | MileageReportSimplified): string {
    if (!(mm as any).resourceType || (mm as MileageReportSimplified).resourceType === 'standalone_mileage_report') return `/time-tracking/mm-details/${mm.id}`;
    return `/time-tracking/wp-details/${(mm as MileageReportSimplified).workingPeriodId}/wp-km/${mm.id}`;
  }

  private getFastNavigationData(): Params {
    return ({
      data: this.rows.map(r => ({
        id:     r.id,
        page:   this.sessionStorageService.pageNumberValue,
        wp_id: (r as MileageReportSimplified).workingPeriodId
      }))
    });
  }

  get sortedRows(): (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[] {
    return (this.rows as (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[])?.sort((a: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily, b: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily) => this.sortMileageByDate(a, b));
  }

  private prepareMileageMoneyDetailsTableBody(): TableBodyEntryModel[] {
    if (this.rows?.length) {
      let body = this.prepareMileageMoneyDetailsBasicTableBody();
      if (this.rows[0] instanceof StandaloneMileageMoneyDaily) {
        this.sortedRows.forEach((moneyReport, index) => {
          body[index].cells.push({
            xs_label: ' ',
            class: `action-cell am-flex-center ${this.editMode ? '' : 'hide events-none'}`,
            children: [
              { class: `am-flex-center hover-active color-blue z-2 ${this.getWorkDayStateIcon(moneyReport)}`, click: () => this.actionHandler(moneyReport, index) }
            ]
          });
        });
      }
      return body;
    }
  }

  private prepareMileageMoneyDetailsBasicTableBody(): TableBodyEntryModel[] {
    return this.sortedRows.map((moneyReport: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily, index): TableBodyEntryModel => {
      let date = this.getMileageDate(moneyReport);

      return {
        deleted: moneyReport.deleted,
        extra_styles: `relative ${moneyReport.errors?.length ? 'color-red-i' : moneyReport.mileageRemoved ? 'color-orange' : moneyReport.new ? 'color-green' : ''}`,
        cells: [
          { xs_label: null, class: 'holiday-cell am-flex-column xs-hide', children: [
            { class: 'font-icon icon-holiday hide', skip: this.checkIfHoliday(date) || moneyReport.mileageRemoved },
            { class: 'font-icon icon-holiday', skip: !this.checkIfHoliday(date), children: [
              { class: 'web-tooltip orange-border short right', children: [
                { class: 'tooltip-title', value: this.checkIfHoliday(date) }
              ]}
            ]},
            { class: 'font-icon icon-warning', skip: !moneyReport.mileageRemoved, children: [
              { class: 'web-tooltip orange-border short right', children: [
                { class: 'tooltip-title', value: 'Notiz: Dieses Datum ist nicht gültig.' }
              ]}
            ]}
          ]},
          { xs_label: 'Datum', class: 'day-cell', children: [
            { class: 'am-flex-column am-flex-tablet-row am-flex-tablet-align gap-xxs', children: [
              { value: formatDate(date, 'EEEE',       'de') },
              { value: formatDate(date, 'dd.MM.yyyy', 'de') }
            ]}
          ]},
          { xs_label: 'Von', class: 'from-cell', editableInput: {
            index: ['2', index, this.index].join(''),
            editBox: this.editBox,
            type: 'text',
            value: moneyReport.externalEmployeeAddress,
            showEdit: this.editMode && !moneyReport.deleted,
            toggleEditBox: (val) => this.editBoxHandler.emit(val),
            onValueChange: (value) => this.updateValue(value, index, 'externalEmployeeAddress')
          }},
          { xs_label: 'Nach', class: 'to-cell', editableInput: {
            index: ['1', index, this.index].join(''),
            editBox: this.editBox,
            type: 'text',
            value: moneyReport.assignmentAddress,
            showEdit: this.editMode && !moneyReport.deleted,
            toggleEditBox: (val) => this.editBoxHandler.emit(val),
            onValueChange: (value) => this.updateValue(value, index, 'assignmentAddress')
          }},
          { xs_label: 'Entfernung in km', class: 'total-km-cell', editableInput: {
            index: ['3', index, this.index].join(''),
            editBox: this.editBox,
            type: 'number',
            maxValue: 1000,
            value: moneyReport.totalAmountOfKm+'',
            valueType:'distance',
            postValue: 'km',
            showEdit: this.editMode && !moneyReport.deleted,
            toggleEditBox: (val) => this.editBoxHandler.emit(val),
            onValueChange: (value) => this.updateValue(parseFloat(value.replace(',', '.'))/2, index, 'amountOfKm')
          }}
        ]
      };
    });
  }

  private getWorkDayStateIcon(moneyReport): string {
    if (moneyReport.deleted) return 'font-icon       icon-plus';
    return                          'font-small-icon icon-bin';
  }

  private actionHandler(moneyReport, index: number = null): void {
    if  (moneyReport.deleted) moneyReport.deleted = false;
    else if (moneyReport.new) this.rows.splice(index, 1);
    else                      moneyReport.deleted = true;
    this.validate.emit();
  }

  isSMM(mileageReport): boolean {
    return mileageReport instanceof StandaloneMileageMoneyDaily;
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  private updateValue(value: any, index: number, field: string): void {
    let path = ['workGroups', this.index, 'workDays', index, field];
    this.valueChangeHandler.emit({ value, path });
  }

  private getMileageDate(mileage: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily): Date {
    return (mileage as ActivityReportMileageMoneyDaily).startedAt || (mileage as StandaloneMileageMoneyDaily).date;
  }

  private sortMileageByDate(a: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily, b: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily): number {
    let dateA = this.getMileageDate(a);
    let dateB = this.getMileageDate(b);

    return dateA.getTime() - dateB.getTime();
  }

}
