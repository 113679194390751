// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper .search-input-container #dashboard-search {
  height: 36px;
  max-width: 465px;
  padding-right: 30px;
}
@media (max-width: 768px) {
  .search-wrapper .search-input-container #dashboard-search {
    max-width: 768px;
  }
}
.search-wrapper .search-input-container .clear-input {
  margin-left: -30px;
  padding: 9px 12px;
  font-size: 12px;
  cursor: pointer;
}
.search-wrapper .search-input-container .clear-input:hover {
  opacity: 0.7;
}
@media (min-width: 640px) {
  .search-wrapper .search-input-container .tooltip-icon:hover .web-tooltip {
    display: flex;
  }
}
.search-wrapper .search-suggestion {
  max-width: 465px;
}
@media (max-width: 768px) {
  .search-wrapper .search-suggestion {
    max-width: 768px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
