import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';

import {
  UserService,
  SessionStorageService,
  SidebarStorageService,
  NotificationService,
  FailedErpService,
  ActivityReportsService,
  AssignmentService,
  PhotoDocumentService,
  InvoicesService,
  VacationRequestListService,
  MileageMoneyListService,
} from '@shared/services';

import {
  ActivityReportsCountersModel,
  ActivityReportsCountersTokenModel,
  TogglerModel,
  VacationRequestsCountersModel,
  WsMessageModel
} from '@shared/models';

@Injectable()
export class CountersService {
  constructor (
    private router:                     Router,
    private activityReportsService:     ActivityReportsService,
    private failedErpService:           FailedErpService,
    private photoDocumentService:       PhotoDocumentService,
    private assignmentService:          AssignmentService,
    private vacationRequestListService: VacationRequestListService,
    private invoicesService:            InvoicesService,
    private mileageMoneyListService:    MileageMoneyListService,
    private sessionStorageService:      SessionStorageService,
    private sidebarStorageService:      SidebarStorageService,
    private userService:                UserService,
    private notificationService:        NotificationService
  ) { }

  checkForUpdates(newTokens, useCase: string, skipNotification: boolean = null): void {
    let oldTokens = this.sessionStorageService[`${useCase}CountersTokensValue`];
    if (oldTokens) {
      let newEntries     = {};
      let deletedEntries = {};

      for (const key in newTokens) { newEntries[key]     = newTokens[key].filter(nt => !nt.creator).filter(nt => !oldTokens[key].find(ot => nt.id === ot.id)); }
      for (const key in newTokens) { deletedEntries[key] = oldTokens[key].filter(nt => !newTokens[key].find(ot => nt.id === ot.id));                           }
      this.needUpdate(newEntries, deletedEntries, useCase, skipNotification);
    }
    this.sessionStorageService[`change${this.capitalize(useCase)}CountersTokens`](newTokens);
  }

  private needUpdate(newEntries, deletedEntries, useCase: string, skipNotification: boolean = null): void {
    let tab    = this.sessionStorageService.activeTabValue;
    let update = Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length;

    if (tab && update) {
      switch (useCase) {
        case 'activityReports':  { this.activityReportsNeedUpdate(newEntries,  deletedEntries, skipNotification); break; }
        case 'vacationRequests': { this.vacationRequestsNeedUpdate(newEntries, deletedEntries);                   break; }
        case 'photoDocuments':
        case 'invoices':
        case 'EBS':
        case 'assignments':
        case 'mileageMoneyReports':
          this.generalUpdate(newEntries, deletedEntries, useCase);
          break;
        default:
          break;
      };
    }
  }

  private generalUpdate(newEntries, deletedEntries, useCase: string): void {
    let update = this.getEntriesLength(newEntries) + this.getEntriesLength(deletedEntries);
    if (update) this.reloadByUseCase(useCase);
  }

  private reloadByUseCase(useCase: string) {
    switch (useCase) {
      case 'photoDocuments':      { if (this.router.url.includes('/time-tracking/pd-list'))          this.photoDocumentService.forceReload();    break; }
      case 'invoices':            { if (this.router.url.includes('/invoices/list'))                  this.invoicesService.forceReload();         break; }
      case 'EBS':                 { if (this.router.url.includes('/time-tracking/ebs-list'))         this.assignmentService.forceReload();       break; }
      case 'assignments':         { if (this.router.url.includes('/time-tracking/assignments-list')) this.assignmentService.forceReload();       break; }
      case 'mileageMoneyReports': { if (this.router.url.includes('/time-tracking/mm-list'))          this.mileageMoneyListService.forceReload(); break; }
      default:
        break;
    }
  }

  private getEntriesLength(object: any): number {
    return Object.keys(object).reduce((sum, key) => sum + object[key].length, 0);
  }

  private activityReportsNeedUpdate(newEntries: ActivityReportsCountersModel, deletedEntries: ActivityReportsCountersModel, skipNotification: boolean = null): void {
    this.reloadByUrl('/time-tracking/list',       '',          () => this.activityReportsService.forceReload(), !!(Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length) );
    this.reloadByUrl('/time-tracking/list',       'approved',  () => this.activityReportsService.forceReload(), !!(newEntries.approved.length              + deletedEntries.approved.length)              );
    this.reloadByUrl('/time-tracking/list',       'open',      () => this.activityReportsService.forceReload(), !!(newEntries.open.length                  + deletedEntries.open.length)                  );
    this.reloadByUrl('/time-tracking/list',       'rejected',  () => this.activityReportsService.forceReload(), !!(newEntries.rejected.length              + deletedEntries.rejected.length)              );
    if (this.userService.isInternal)
    this.reloadByUrl('/time-tracking/failed-erp', 'ar-failed', () => this.failedErpService.forceReload(),       !!(newEntries.failed_export.length         + deletedEntries.failed_export.length), 'failed_erp', 'ar' );

    if (!skipNotification) this.needNotification(newEntries, 'activityReports');
  }

  private vacationRequestsNeedUpdate(newEntries: VacationRequestsCountersModel, deletedEntries: VacationRequestsCountersModel): void {
    this.reloadByUrl('/time-tracking/vr-list',    '',            () => this.vacationRequestListService.forceReload(), !!(Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length) );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-approved', () => this.vacationRequestListService.forceReload(), !!(newEntries.approved.length              + deletedEntries.approved.length)              );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-open',     () => this.vacationRequestListService.forceReload(), !!(newEntries.open.length                  + deletedEntries.open.length)                  );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-rejected', () => this.vacationRequestListService.forceReload(), !!(newEntries.rejected.length              + deletedEntries.rejected.length)              );
    if (this.userService.isInternal)
    this.reloadByUrl('/time-tracking/failed-erp', 'vr-failed',   () => this.failedErpService.forceReload(),           !!(newEntries.failed_export.length         + deletedEntries.failed_export.length), 'failed_erp', 'vr');
  }

  private reloadByUrl(url: string, filter: string, reloadCallback: Function, needReload: boolean = false, togglerField: string = null, togglerValue: string = null): void {
    let activeToggler, tab = this.sessionStorageService.activeTabValue;
    if (togglerField) activeToggler = this.sessionStorageService.headerTogglersValue[togglerField]?.find((tog: TogglerModel) => tog.active);

    if (tab && needReload && this.router.url.includes(url) && (!tab.counter || tab.counter === filter) && (!activeToggler || activeToggler.value === togglerValue)) {
      this.notificationService.wait();
      this.sessionStorageService.reloadAvailable = true;
      reloadCallback.call(this);
    }
  }

  resourceStateTransitionHandler(message: WsMessageModel): void {
    for (const key in message.board_states) {
      let counterName    = this.getCounterObservableName( message.type, key);
      let counterChange  = message.board_states[key]
      let url            = this.getUrlByUseCase(          message.type, key);
      let filter         = this.getFilterByUseCase(       message.type, key);
      let reloadCallback = this.getReloadHandlerByUseCase(message.type, key);
      let togglerField   = this.getTogglerFieldByUseCase( message.type, key);
      let togglerValue   = this.getTogglerByUseCase(      message.type, key);

      if (counterName) this.counterCountHandler(counterName, counterChange, url, filter, reloadCallback, togglerField, togglerValue);
    };
    if (message.type === 'working_period' && this.sessionStorageService.latestCreatedByMe?.find(id => id !== message.id)) {
      this.showNewReportsDesktopNotification([{ id: message.id, creator: null }]);
    }
  }

  private getCounterObservableName(useCase: string, counter: string): string {
    switch (useCase) {
      case 'working_period':            { switch (counter) {
        case 'approved':                { return 'approvedReports';              }
        case 'open':                    { return 'openActivityReports';          }
        case 'rejected':                { return 'clarificationReports';         }
        case 'failed_export':           { return 'failedExportActivityReports'   }
      }};id
      : 
      1657
      case 'vacation_request':          { switch (counter) {
        case 'approved':                { return 'approvedVacationRequests';     }
        case 'open':                    { return 'awaitingVacationRequests';     }
        case 'rejected':                { return 'rejectedVacationRequests';     }
        case 'failed_export':           { return 'failedExportVacationRequests'; }
      }};
      case 'customer_invoice':          { switch (counter) {
        case 'active':                  { return 'activeInvoices';               }
        case 'archived':                { return 'archivedInvoices';             }
      }};
      case 'photo_document':            { switch (counter) {
        case 'unread':                  { return 'activePhotoDocuments';         }
      }};
      case 'assignment':
      case 'ebs_data':
                                        { switch (counter) {
        case 'unconfirmed':             { return 'unconfirmedEBS';               }
        case 'expire_soon':             { return 'expiringSoonAssignments';      }
      }};
      case 'standalone_mileage_report': { switch (counter) {
        case 'open':                    { return 'awaitingMileageMoneyReports';  }
      }};
    };
  };

  private getUrlByUseCase(useCase: string, counter: string): string {
    if (counter === 'failed_export')    { return '/time-tracking/failed-erp';       }
    else switch (useCase) {
      case 'working_period':            { return '/time-tracking/list';             }
      case 'photo_document':            { return '/time-tracking/pd-list';          }
      case 'vacation_request':          { return '/time-tracking/vr-list';          }
      case 'assignment':
      case 'ebs_data':                  { switch (counter) {
        case 'unconfirmed':             { return  '/time-tracking/ebs-list';        }
        case 'expire_soon':             { return '/time-tracking/assignments-list'; }
      }};
      case 'standalone_mileage_report': { return '/time-tracking/mm-list';          }
      case 'customer_invoice':          { return '/invoices/list';                  }
    };
  }

  private getFilterByUseCase(useCase: string, counter: string): string {
    switch (useCase) {
      case 'working_period':            { switch (counter) {
        case 'approved':                { return 'approved';      }
        case 'open':                    { return 'open';          }
        case 'rejected':                { return 'rejected';      }
        case 'failed_export':           { return 'failed_export'; }
      }};
      case 'vacation_request':          { switch (counter) {
        case 'approved':
        case 'open':
        case 'rejected':                {
          if (this.userService.isInternal) return 'vr-approved';
          if (this.userService.isCustomer) return 'vr-awaiting';
        }
        case 'failed_export':           { return 'failed_export'; }
      }};
      case 'customer_invoice':          { switch (counter) {
        case 'active':                  { return 'active';        }
        case 'archived':                { return 'archived';      }
      }};
      case 'photo_document':            { switch (counter) {
        case 'unread':                  { return 'photo';         }
      }};
      case 'assignment':
      case 'ebs_data':                  { switch (counter) {
        case 'expire_soon':
        case 'unconfirmed':             { return 'ebs';           }
      }};
      case 'standalone_mileage_report': { switch (counter) {
        case 'open':                    { return 'mm-open';       }
      }};
    };
  };

  private getReloadHandlerByUseCase(useCase: string, counter: string): string {
    if (counter === 'failed_export')    { return 'failedErpService';           }
    else switch (useCase) {
      case 'working_period':            { return 'activityReportsService';     }
      case 'photo_document':            { return 'photoDocumentService';       }
      case 'vacation_request':          { return 'vacationRequestListService'; }
      case 'assignment':
      case 'ebs_data':                  { return 'assignmentService';          }
      case 'customer_invoice':          { return 'invoicesService';            }
      case 'standalone_mileage_report': { return 'mileageMoneyListService';    }
    };
  }

  private getTogglerFieldByUseCase(useCase: string, counter: string): string {
    switch (useCase) {
      case 'working_period':   { switch (counter) {
        case 'failed_export':  { return 'failed_erp';    }
      }};
      case 'vacation_request': { switch (counter) {
        case 'approved':
        case 'open':
        case 'rejected':       { return 'vacations';     }
        case 'failed_export':  { return 'failed_export'; }
      }};
    };
  };

  private getTogglerByUseCase(useCase: string, counter: string): string {
    switch (useCase) {
      case 'working_period':   { switch (counter) {
        case 'failed_export':  { return 'ar';       }
      }};
      case 'vacation_request': { switch (counter) {
        case 'approved':       { return 'approved'; }
        case 'open':           { return 'open';     }
        case 'rejected':       { return 'rejected'; }
        case 'failed_export':  { return 'vr';       }
      }};
    };
  }

  private counterCountHandler(counterName: string, counterChange: number, url: string, filter: string, reloadCallback: string, togglerField: string = null, togglerValue: string = null): void {
    let count = this.sidebarStorageService[`${counterName}CountValue`];
    this.sidebarStorageService[`change${this.capitalize(counterName)}Count`](count + counterChange);
    this.reloadByUrl(url, filter, () => this[reloadCallback].forceReload(), true, togglerField, togglerValue);
  }

  private needNotification(newEntries: any, useCase: string): void {
    switch (useCase) {
      case 'activityReports':
        this.activityReportsNotification(newEntries);
        break;
    };
  }

  private activityReportsNotification(newEntries: ActivityReportsCountersModel): void {
    if (this.userService.isInternal && (newEntries?.approved?.length || newEntries?.rejected?.length)) {
      let entries = [...newEntries?.approved, ...newEntries?.rejected].filter(e => !e.creator);
      this.showNewReportsDesktopNotification(entries);
    } else if (this.userService.isCustomer && newEntries?.open?.length) {
      this.showNewReportsDesktopNotification(newEntries?.open.filter(e => !e.creator));
    }
  }

  private showNewReportsDesktopNotification(entries: ActivityReportsCountersTokenModel[]): void {
    if (!('Notification' in window)) return;
    if (Notification.permission !== 'granted') Notification.requestPermission();
    else {
      let notification = new Notification(this.userService.isCustomer ? 'myTempton Kundenverwaltung' : 'myTempton SB Verwaltung',{ 
        icon: 'assets/images/icon/favicon.ico',
        body: `Ein neuer Tätigkeitsnachweis steht zur Verfügung (${entries.length})`
      });

      notification.onclick = () => {
        window.focus();
        if (entries[0]?.id) {
          this.router.navigateByUrl(`/time-tracking/wp-details/${entries[0].id}`);
          this.sessionStorageService.changePageNumber(1);
          this.sessionStorageService.changeTotalPages(1);
          if (window?.history?.state?.data) window.history.state.data = null;

        }
      };
    }
  }

  private capitalize(string: string): string {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  }

}